import { useEffect } from "react";
import is from "../utils/is";

export default function useKeyPress(element = document, targetKey, handler) {
  useEffect(() => {
    function upHandler(event) {
      if (is.array(targetKey) && targetKey.includes(event.key)) {
        handler(event);
      } else if (event.key === targetKey) {
        handler(event);
      }
    }
    element && element.addEventListener("keydown", upHandler);
    return () => {
      element && element.removeEventListener("keydown", upHandler);
    };
  }, [element, handler, targetKey]);
}
