import axios from "axios";
import Const from "../constants";
import { getRefreshToken } from "./auth.api";
import { clearTokenDataFromStorage, getTokenDataFromStorage, setTokenDataToSession, setTokenDataToStorage } from "../contexts/token.context";
import deepMergeObjects from "../utils/deep-merge-objects";
import { useCallback, useEffect, useRef } from "react";
import { useNotificationModalContext } from "../components/notification-modal/provider";
import { useLocation, useNavigate } from "react-router-dom";
import MESSAGES from "../constants/messages";

const mainInstance = axios.create({
  headers: { "Content-Type": "application/json" },
  baseURL: `${Const.API_URL}`,
});

export default mainInstance;

export function withConfig(fn) {
  return (data) => {
    let { signal, ...restData } = data;
    const configure = (config) => {
      let defaultConfig = {};
      if (signal) {
        defaultConfig.signal = signal;
      }
      return deepMergeObjects(defaultConfig, config);
    };
    return fn(restData, configure);
  };
}

export function getApi(routeGroup = "") {
  return (config) => {
    let { url, ...restConfig } = config;
    let updatedUrl = url;
    if (routeGroup) {
      updatedUrl = `/${routeGroup}/${url}`.replaceAll("//", "/");
    }
    return mainInstance({
      url: updatedUrl,
      ...restConfig,
    });
  };
}

export function AxiosInterceptorProvider(props) {
  const notificationModal = useNotificationModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const activeRequestsRef = useRef([]);
  const registerRequest = useCallback((request) => {
    activeRequestsRef.current.push(request);
  }, []);

  const unregisterRequest = useCallback((request) => {
    activeRequestsRef.current = activeRequestsRef.current.filter(r => r !== request);
  }, []);
  useEffect(() => {
    return () => {
      activeRequestsRef.current.forEach((request) => {
        if (request.cancel) {
          request.cancel("Route changed");
        }
      });
      activeRequestsRef.current = [];
    };
  }, [location.pathname]);
  useEffect(() => {
    function handleError401(error) {
      return new Promise((resolve) => {
        notificationModal.error({
          title: "Unauthorized.",
          heading: "You will be redirected to the Login page. Please try logging in again.",
          onClose: () => {
            //__TODO__: need modification
            clearTokenDataFromStorage();
            navigate("/");
            resolve();
          },
        });
      });
    }
    function handleError403(error) {
      return new Promise((resolve) => {
        if (error.response.data.error === "jwt expired" || error.response.data.error === "refresh-token expired") {
          notificationModal.error({
            title: MESSAGES.SESSION_EXPIRED.TITLE,
            heading: MESSAGES.SESSION_EXPIRED.HEADING,
            closeText: "Ok",
            onClose: () => {
              //__TODO__: need modification
              clearTokenDataFromStorage();
              navigate("/");
              resolve();
            },
          });
        } else {
          notificationModal.error({
            title: MESSAGES.ACCESS_DENIED.TITLE,
            heading: MESSAGES.ACCESS_DENIED.HEADING,
            closeText: "Ok",
            onClose: () => {
              //__TODO__: need modification
              clearTokenDataFromStorage();
              navigate("/");
              resolve();
            },
          });
        }
      });
    }
    function handleError500(error) {
      return new Promise((resolve) => {
        notificationModal.error({
          title: MESSAGES.TECHNICAL_ERROR.TITLE,
          heading: MESSAGES.TECHNICAL_ERROR.HEADING,
          closeText: "Ok",
          onClose: () => {
            resolve();
          },
        });
      });
    }
    function onBeforeRequestSent(config) {
      //__TODO__: need modification
      const accessToken = getTokenDataFromStorage(Const.TOKEN_TYPE.ACCESS);
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      const source = axios.CancelToken.source();
      config.cancelToken = source.token;
      registerRequest({ cancel: source.cancel });
      return config;
    }
    function onRequestError(error) {
      return Promise.reject(error);
    }
    function onBeforeResponseRecieve(response) {
      const { access_token, refresh_token } = response.data;
      if (access_token) {
        //__TODO__: need modification
        setTokenDataToStorage(Const.TOKEN_TYPE.ACCESS, access_token);
        setTokenDataToSession(Const.TOKEN_TYPE.ACCESS, access_token);
      }
      if (refresh_token) {
        //__TODO__: need modification
        setTokenDataToStorage(Const.TOKEN_TYPE.REFRESH, refresh_token);
        setTokenDataToSession(Const.TOKEN_TYPE.REFRESH, refresh_token);
      }
      unregisterRequest(response.config);
      return response;
    }
    function onResponseError(instance) {
      return async (error) => {
        unregisterRequest(error.config);
        const originalConfig = error.config;

        if (!error.response) {
          return Promise.reject(error);
        }

        let is401 = error.response.status === 401;
        let is403 = error.response.status === 403;
        let is5xx = error.response.status >= 500;
        let isGetMethod = error.response.config.method === "get";
        let isRefreshTokenExpired = is403 && error.response.data.error === "refresh-token expired";

        if (originalConfig._retry) {
          if (is401) {
            return handleError401(error);
          }
          if (is403) {
            return handleError403(error);
          }
        } else {
          if (is5xx) {
            return isGetMethod ? Promise.reject(error) : handleError500(error);
          }
          if (isRefreshTokenExpired) {
            return handleError403(error);
          }
          if (!is401 && !is403) {
            return Promise.reject(error);
          }
        }
        originalConfig._retry = true;

        try {
          const localRefreshToken = getTokenDataFromStorage(Const.TOKEN_TYPE.REFRESH);
          if (!localRefreshToken) return Promise.reject(error);
          const rs = await getRefreshToken({
            refreshToken: localRefreshToken,
          });
          const { access_token, refresh_token } = rs.data;

          //__TODO__: need modification
          setTokenDataToStorage(Const.TOKEN_TYPE.ACCESS, access_token);
          setTokenDataToStorage(Const.TOKEN_TYPE.REFRESH, refresh_token);
          instance.defaults.headers.common[Const.TOKEN_TYPE.ACCESS] = access_token;
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      };
    }
    const requestInterceptor = mainInstance.interceptors.request.use(onBeforeRequestSent, onRequestError);
    const responseInterceptor = mainInstance.interceptors.response.use(onBeforeResponseRecieve, onResponseError(mainInstance));
    return () => {
      // cleanup function
      mainInstance.interceptors.request.eject(requestInterceptor);
      mainInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate, notificationModal, registerRequest, unregisterRequest]);
  return props.children;
}
