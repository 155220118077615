function IconInfo(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" fill="#6CA2FF" />
      <g clipPath="url(#clip0_1931_12564)">
        <path
          d="M24 35.375C20.9832 35.375 18.0899 34.1766 15.9567 32.0433C13.8234 29.9101 12.625 27.0168 12.625 24C12.625 20.9832 13.8234 18.0899 15.9567 15.9567C18.0899 13.8234 20.9832 12.625 24 12.625C27.0168 12.625 29.9101 13.8234 32.0433 15.9567C34.1766 18.0899 35.375 20.9832 35.375 24C35.375 27.0168 34.1766 29.9101 32.0433 32.0433C29.9101 34.1766 27.0168 35.375 24 35.375ZM24 37C27.4478 37 30.7544 35.6304 33.1924 33.1924C35.6304 30.7544 37 27.4478 37 24C37 20.5522 35.6304 17.2456 33.1924 14.8076C30.7544 12.3696 27.4478 11 24 11C20.5522 11 17.2456 12.3696 14.8076 14.8076C12.3696 17.2456 11 20.5522 11 24C11 27.4478 12.3696 30.7544 14.8076 33.1924C17.2456 35.6304 20.5522 37 24 37V37Z"
          fill="white"
        />
        <path
          d="M25.5113 21.7055L21.79 22.1719L21.6568 22.7894L22.388 22.9242C22.8658 23.038 22.96 23.2103 22.856 23.6864L21.6568 29.3219C21.3415 30.7795 21.8274 31.4652 22.9698 31.4652C23.8554 31.4652 24.884 31.0558 25.3504 30.4935L25.4934 29.8175C25.1684 30.1035 24.6939 30.2173 24.3786 30.2173C23.9318 30.2173 23.7693 29.9036 23.8846 29.3511L25.5113 21.7055ZM25.625 18.3125C25.625 18.7435 25.4538 19.1568 25.1491 19.4615C24.8443 19.7663 24.431 19.9375 24 19.9375C23.569 19.9375 23.1557 19.7663 22.851 19.4615C22.5462 19.1568 22.375 18.7435 22.375 18.3125C22.375 17.8815 22.5462 17.4682 22.851 17.1635C23.1557 16.8587 23.569 16.6875 24 16.6875C24.431 16.6875 24.8443 16.8587 25.1491 17.1635C25.4538 17.4682 25.625 17.8815 25.625 18.3125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1931_12564">
          <rect width="26" height="26" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconInfo;
