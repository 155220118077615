import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export default function Portal(props) {
  const { parent, children } = props;
  return ReactDOM.createPortal(children, parent || document.body);
}

Portal.defaultProps = {
  parent: null,
  children: null,
};

Portal.propTypes = {
  parent: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.node]),
  children: PropTypes.node,
};
