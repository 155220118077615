import { withConfig, getApi } from "./index";
const api = getApi("me");
export const getUserInfo = () => {
  return api({
    url: "",
    method: "GET",
  });
};

export const getMyAuditsContractReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/contract-reviews",
    method: "GET",
    params: data,
  });
  return api(config);
});

export const getTawaRequestReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/tawa/contract-reviews",
    method: "GET",
    params: data,
  });

  return api(config);
});

export const getTawaRequestReviewById = withConfig((data, conf) => {
  const { id } = data;
  const config = conf({
    url: `/tawa/contract-reviews/${id}`,
    method: "GET",
  });

  return api(config);
});

export const getRpsRequestReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/rps/contract-reviews",
    method: "GET",
    params: data,
  });

  return api(config);
});

export const getBsaRequestReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/bs/contract-reviews",
    method: "GET",
    params: data,
  });

  return api(config);
});
export const getSSARequestReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/ssa/contract-reviews",
    method: "GET",
    params: data,
  });

  return api(config);
});
export const getPCSRequestReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/pcs/contract-reviews",
    method: "GET",
    params: data,
  });

  return api(config);
});
export const getMyAuditsNCContractReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/ncs",
    method: "GET",
    params: data,
  });
  return api(config);
});
export const getNCSurveyReporting = withConfig((data, conf) => {
  const { reportHeaderId, ...params } = data;
  let config = conf({
    url: `/${reportHeaderId}/ncs`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const getSRFARequestReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/srfa/contract-reviews",
    method: "GET",
    params: data,
  });
  return api(config);
});


export const getWcRequestReviewList = withConfig((data, conf) => {
  let config = conf({
    url: "/wc/contract-reviews",
    method: "GET",
    params: data,
  });

  return api(config);
});