import cx from "../../utils/class-names";
import { useNotificationModalContext } from "./provider";

function Overlay(props) {
  const { children } = props;

  const notificationModal = useNotificationModalContext();

  return (
    <div
      onClick={notificationModal.handleOverlayClick}
      className={cx(
        "notification-modal_overlay h-screen w-screen bg-black/30 z-10000 fixed inset-0 flex invisible transition-all delay-75",
        notificationModal.open && "visible",
        notificationModal.classNames?.root
      )}
    >
      {children}
    </div>
  );
}

export default Overlay;
