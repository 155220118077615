import { Navigate } from "react-router-dom";
import RoutesPublic from "../components/routes-public";
import withLoadable from "../components/with-loadable";

const AuthLoginPage = withLoadable(() => import("../pages/auth/login.page"));
const AuthOAuth2LoginPage = withLoadable(() => import("../pages/auth/oauth2-login.page"));
const AuthRedirectPage = withLoadable(() => import("../pages/auth/redirect.page"));
const AuthUserChangedPage = withLoadable(() => import("../pages/auth/user-changed.page"));

const PeriodicalSurveyRequestFormPage = withLoadable(() => import("../pages/periodical-survey/survey-request-form.page"));
const CreateNewCompanyUserPage = withLoadable(() => import("../pages/auth/new-company-user.page"));
const TawaCreateNewCompanyUserPage = withLoadable(() => import("../pages/auth/new-company-user-tawa"));

/**
 *
 * Routes which are available until user is not logged in,
 * once logged in, user will not be able to visit this routes
 */
export default function getProtectedRoutes() {
  return [
    {
      element: RoutesPublic,
      elementProps: { restricted: true },
      children: [
        { path: "/auth/login", element: AuthLoginPage },
        { path: "/auth/oauth2/login", element: AuthOAuth2LoginPage },
        { path: "/auth/redirect", element: AuthRedirectPage },
        { path: "/auth/user-changed", element: AuthUserChangedPage },
        { path: "/survey-request/periodical", element: PeriodicalSurveyRequestFormPage },
        { path: "/", element: Navigate, elementProps: { to: "/auth/login" } },
        { path: "/create-user/:entity", element: CreateNewCompanyUserPage },
        { path: "/register-company/:module", element: TawaCreateNewCompanyUserPage },
      ],
    },
  ];
}
